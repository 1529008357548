import { memo, useMemo, forwardRef } from 'react';
import cn from 'classnames';

import { BoxProps } from './types';
import classes from './Box.module.scss';

export const Box = memo(forwardRef<HTMLDivElement, BoxProps>(({
  children,
  alignItems = 'stretch',
  direction = 'row',
  wrap = 'nowrap',
  justifyContent = 'flex-start',
  className,
  onClick,
  Tag = 'div',
  ...props
}, ref) => {
  const resultClassName = useMemo(() => {
    return cn(classes.flex, {
      [classes['direction-row']]: direction === 'row',
      [classes['direction-column']]: direction === 'column',
      [classes['direction-column-reverse']]: direction === 'column-reverse',
      [classes['direction-row-reverse']]: direction === 'row-reverse',
      [classes['wrap-nowrap']]: wrap === 'nowrap',
      [classes['wrap-wrap']]: wrap === 'wrap',
      [classes['wrap-wrap-reverse']]: wrap === 'wrap-reverse',
      [classes['justify-center']]: justifyContent === 'center',
      [classes['justify-flex-end']]: justifyContent === 'flex-end',
      [classes['justify-flex-start']]: justifyContent === 'flex-start',
      [classes['justify-space-around']]: justifyContent === 'space-around',
      [classes['justify-space-between']]: justifyContent === 'space-between',
      [classes['align-items-baseline']]: alignItems === 'baseline',
      [classes['align-items-center']]: alignItems === 'center',
      [classes['align-items-flex-end']]: alignItems === 'flex-end',
      [classes['align-items-flex-start']]: alignItems === 'flex-start',
      [classes['align-items-stretch']]: alignItems === 'stretch',
    }, className);
  }, [direction, wrap, justifyContent, alignItems, className]);

  return (
    <Tag
      className={resultClassName}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </Tag>
  );
}));
