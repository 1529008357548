export type IconParams = {
  id: string;
  width: number;
  height: number;
  viewBox: string;
}

export const iconParams = {
  close: {
    id: 'close',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  plus: {
    id: 'plus',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  settings: {
    id: 'settings',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  bag: {
    id: 'bag',
    width: 13,
    height: 15,
    viewBox: '0 0 13 15',
  },
  menu: {
    id: 'menu',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  app: {
    id: 'app',
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
  },
  copy: {
    id: 'copy',
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
  },
  circle: {
    id: 'circle',
    width: 12,
    height: 10,
    viewBox: '0 0 12 10',
  },
  arrow_left: {
    id: 'arrow_left',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  arrow_left_small: {
    id: 'arrow_left_small',
    width: 11,
    height: 9,
    viewBox: '0 0 11 9',
  },
  arrow_right: {
    id: 'arrow_right',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  check: {
    id: 'check',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  check_small: {
    id: 'check_small',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  plus_small: {
    id: 'plus_small',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  search: {
    id: 'search',
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
  },
  info: {
    id: 'info',
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
  },
  info_w: {
    id: 'info_w',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  clip: {
    id: 'clip',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  trash: {
    id: 'trash',
    width: 9,
    height: 11,
    viewBox: '0 0 9 11',
  },
  triangle: {
    id: 'triangle',
    width: 8,
    height: 6,
    viewBox: '0 0 8 6',
  },
  dnd: {
    id: 'dnd',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  refresh: {
    id: 'refresh',
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
  },
  cross: {
    id: 'cross',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  check_rounded: {
    id: 'check_rounded',
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
  },
  close_rounded: {
    id: 'close_rounded',
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
  },
  close_small: {
    id: 'close_small',
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
  },
  info_light: {
    id: 'info_light',
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
  },
  trash_big: {
    id: 'trash_big',
    width: 14,
    height: 16,
    viewBox: '0 0 10 12',
  },
  copy_big: {
    id: 'copy_big',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  disabled: {
    id: 'disabled',
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
  },
  soon: {
    id: 'soon',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  link_arrow: {
    id: 'link_arrow',
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
  },
  minus: {
    id: 'minus',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  multiple: {
    id: 'multiple',
    width: 6,
    height: 6,
    viewBox: '0 0 6 6',
  },
  'app-4': {
    id: 'app-4',
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
  },
  download: {
    id: 'download',
    width: 13,
    height: 14,
    viewBox: '0 0 13 14',
  },
  eye: {
    id: 'eye',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  crossed_eye: {
    id: 'crossed_eye',
    width: 17,
    height: 16,
    viewBox: '0 0 16 17',
  },
  triangle_info: {
    id: 'triangle_info',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  cross_in_circle: {
    id: 'cross_in_circle',
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
  },
  caution_big: {
    id: 'caution_big',
    width: 251,
    height: 229,
    viewBox: '0 0 251 229',
  },
  question: {
    id: 'question',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  exclamation_grey: {
    id: 'exclamation_grey',
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
  },
  exclamation_orange: {
    id: 'exclamation_orange',
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
  },
  exclamation_medium: {
    id: 'exclamation_medium',
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
  },
  exclamation_orange_medium: {
    id: 'exclamation_orange_medium',
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
  },
  discord_small: {
    id: 'discord_small',
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
  },
};

export const names = Object.keys(iconParams);

export const getStyleDimensions = (params: IconParams) => (
  { width: `${params.width}px`, minWidth: `${params.width}px`, height: `${params.height}px` }
);
